.page {
  @screen lg {
    height: auto;
    max-height: 86vh;
  }
}

.fixedHeight {
  @screen lg {
    height: 80vh;
    max-height: 624px;
    border-radius: 1rem;
  }
}
