.overlay {
  background: linear-gradient(
    to bottom,
    rgba(28, 32, 41, 0) 0%,
    rgba(28, 32, 41, 0) 50%,
    rgba(28, 32, 41, 1) 100%
  );

  @screen lg {
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 55%,
      rgba(255, 255, 255, 0.5) 80%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}
