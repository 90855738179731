.colRank {
  @apply shrink-0 grow;
  flex-basis: theme('spacing.8');
}

.colName {
  flex-grow: 3;
  flex-basis: 35%;
}

.colStat {
  @apply shrink-0 grow;
  flex-basis: 20%;
}

.colAction {
  @apply shrink-0 grow;
  flex-basis: theme('spacing.8');
}
