.items-2 {
  > * {
    @apply w-1/2;
  }
}

.items-3 {
  > * {
    @apply w-1/3;
  }
}

.items-4 {
  > * {
    @apply w-1/4;
  }
}

.items-5 {
  > * {
    @apply w-1/5;
  }
}
